import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  width: 90%;
  margin: 0 auto;
  padding: 3em 0;
`;

export default Wrapper;
