import React from 'react';
import styled from 'styled-components';
import Wrapper from '../Wrapper';

const StyledSection = styled.section`
  background-color: ${({ color }) => color || 'initial'};
`;

// eslint-disable-next-line react/prop-types
const Section = ({ children, color, ...props }) => (
  <StyledSection color={color}>
    <Wrapper {...props}>{children}</Wrapper>
  </StyledSection>
);

export default Section;
