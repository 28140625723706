import styled from 'styled-components';

const Button = styled.button`
  display: inline-block;
  padding: 0.25em 0.8em;
  color: white;
  background-color: ${({ theme }) => theme.color.orange};
  border: none;
  border-radius: 1.5em;
  box-shadow: 0 0.35em 0 #da3f14;
  transition: transform 150ms ease-in-out, box-shadow 150ms ease-in-out;
  cursor: pointer;

  :hover {
    transform: translateY(-0.15em);
    box-shadow: 0 0.5em 0 #da3f14;
  }

  :focus {
    outline: none;
  }

  :active {
    transform: translateY(0.15em);
    box-shadow: 0 0.2em 0 #da3f14;
  }
`;

export default Button;
